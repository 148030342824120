import { createGlobalStyle } from "styled-components"
import variables from "./variables"
import defaultStyles from "./normalize"
import typography from "./typography"
import normalize from "./normalize"
import others from "./others"

export const GlobalStyle = createGlobalStyle`
  ${variables}
  ${defaultStyles}
  ${typography}
  ${normalize}
  ${others}
`
