/**
 * the website is build based on desktop design, and everything is designed for 1024 to 1440px
 * so there are some basic query usages:
 *
 * 1. for normal desktop: no query
 * 2. for tablet: @media(max-width: 1024px)
 * 3. for mobile: @media(max-width: 680px)
 * 4. for large screen: @media(min-width: 1680px)
 *
 * for this function, it says:
 * 1. for normal desktop: no query
 * 2. for tablet: {tablet} {...css...}
 * 3. for mobile: {mobile} {...css...}
 * 4. for large screen: {large} {...css...}
 */
const breakpoints = {
  mobile: "680px",
  tablet: "1024px",
  lgDesktop: "1680px",
}

export const tablet = `@media(max-width: ${breakpoints.tablet})`
export const mobile = `@media(max-width: ${breakpoints.mobile})`
export const large = `@media(min-width: ${breakpoints.lgDesktop})`

/**
 * React-Media is used to determine media query in JS
 * 
 * usage:
 * import { useMedia } from 'react-media';
 * import { GLOBAL_MEDIA_QUERIES } from "~utilities/mediaQuery"
 * 
 * const match = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
 * 
 * {match.movbile && <Mobileview>}
 * 
 * turns out gatsby build in server side so there's no way to get the
 * proper window size for first render. Moveback to traditional CSS for 
 * conditionally render.
 */
// export const GLOBAL_MEDIA_QUERIES = {
//   tablet: `(max-width: ${breakpoints.tablet})`,
//   mobile: `(max-width: ${breakpoints.mobile})`,
//   large: `(min-width: ${breakpoints.lgDesktop})`,
// };
