import { mobile, tablet } from "~utilities/mediaQuery"

export default `
  h1 {
    padding: 0;
    margin: 0;
    margin-bottom: 1.45rem;
    color: inherit;
    font-family: "JetBrains Mono", sans-serif;
    font-weight: bold;
    text-rendering: optimizeLegibility;
    font-size: 58px;
    line-height: 1.2;

    ${tablet} {
      font-size: 46px;
    }

    ${mobile} {
      font-size: 20px;
      line-height: 1.6;
    }
  }

  h2 {
    padding: 0;
    margin: 0;
    margin-bottom: 1.45rem;
    color: inherit;
    font-family:  'Hind Siliguri', Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-rendering: optimizeLegibility;
    font-size: 42px;
    line-height: 1.3;

    ${mobile} {
      font-size: 36px;
      line-height: 1.1;
    }
  }

  h3 {
    padding: 0;
    margin: 0;
    margin-bottom: 1.45rem;
    color: inherit;
    font-family: "JetBrains Mono", sans-serif;
    font-weight: bold;
    text-rendering: optimizeLegibility;
    font-size: 26px;
    line-height: normal;

    ${mobile} {
      font-size: 14px;
    }
  }

  h4 {
    padding: 0;
    margin: 0;
    margin-bottom: 1.45rem;
    color: inherit;
    font-family:  'Hind Siliguri', Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-rendering: optimizeLegibility;
    font-size: 18px;
    line-height: normal;
  }

  h5 {
    padding: 0;
    margin: 0;
    color: inherit;
    font-family:  'Hind Siliguri', Arial, Helvetica, sans-serif;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    font-size: 16px;
    line-height: normal;

    ${mobile} {
      font-size: 13px;
    }
  }

  p, span {
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
    color: var(--color-text);
    font-family: inherit;
    font-weight: inherit;
    text-rendering: optimizeLegibility;
    font-size: inherit;
    line-height: inherit;
  }
`
