/*
 * Icon is using IcoMoon app to build (https://icomoon.io/app/#/select/font)
 * Simply feed the `selection.json` to IcoMoon to edit icon set
 */

import React from "react"
import IcomoonReact from "./IcoMoon"
import iconSet from "./selection.json"

const Icon = (props) => {
  const {
    color,
    size = "100%",
    width = "",
    height = "",
    style = {},
    icon,
    className = "",
  } = props
  return (
    <IcomoonReact
      className={className}
      iconSet={iconSet}
      color={color}
      size={size}
      customWidth={width}
      customHeight={height}
      style={style}
      icon={icon}
      {...props}
    />
  )
}

export default Icon
