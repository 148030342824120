export default `
  :root {
    --color-red: #DB2337;
    --color-blue: #1573E5;
    --color-button: #DBDEE3;
    --color-button-text: #11121A;
    --color-text: #93949A;
    --color-bg-1: #333646;
    --color-bg-2: #252735;  
    --color-white: #FFFFFF;
    --color-black: #000000;
  }
`