import frame from "~assets/images/earth-line.png"
import { mobile, tablet } from "~utilities/mediaQuery"

export default `
  .globe {
    position: absolute;
    width: 50vw;
    top: 0;
    right: 0;
    z-index: -1;
    background-image: url("${frame}");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;

    ${tablet} {
      background-image: none;
    }

    ${mobile} {
      width: 100%;
      opacity: 0.7;
      top: 50%;
      transform: translateY(-50%);
      background-size: 75vh;
      background-image: url("${frame}");
    }
  }

  .render-over-tablet {
    ${tablet} {
      display: none;
    }
  }

  .render-below-tablet {
    display: none;

    ${tablet} {
      display: block;
    }
  }

  .render-over-mobile {
    ${mobile} {
      display: none;
    }
  }

  .render-below-mobile {
    display: none;

    ${mobile} {
      display: block;
    }
  }
`
